'use client'

import { getLocaleDomain, getLocaleDisplayName } from '@/src/utilities/languagesConfig'
import { DropdownMenuItem } from '@/src/rebrand/dropdownMenuRadix'
import Link from 'next/link'
import React from 'react'
import { useParams, usePathname } from 'next/navigation'
import { ImageForLanguage } from '@/src/components/languagePicker/LanguagePickerDropdown'
import { locales } from '@/src/constants/locale.constants'
import { getLocalisedUrl } from './utils'

export type StrapiEntryWithLocalizations = {
  id: number
  attributes: { slug: string; localizations: { data: { id: number; attributes: { locale: string; slug: string } }[] } }
}

export type AllLocalizationsType = {
  page: StrapiEntryWithLocalizations[]
  test: StrapiEntryWithLocalizations[]
  article: StrapiEntryWithLocalizations[]
}

const LanguagePickerList: React.FC<{
  allLocalizations: AllLocalizationsType
}> = ({ allLocalizations: allLocalizations }) => {
  const pathname = usePathname()
  const params = useParams()

  const slug = params.slug as string

  return (
    <>
      {locales.map((locale) => {
        const url = getLocalisedUrl(locale, slug, pathname, allLocalizations)
        const domain = getLocaleDomain(locale)
        const text = getLocaleDisplayName(locale)

        return (
          <DropdownMenuItem
            asChild
            key={`${locale}-${text}`}
            className="flex min-w-[200px] items-center px-3 py-1 text-xs font-bold uppercase text-petrol-500 outline-none hover:no-underline focus:text-peach-400"
          >
            <Link href={url}>
              <ImageForLanguage
                language={{
                  text,
                  domain,
                }}
              />
              <div>{text}</div>
            </Link>
          </DropdownMenuItem>
        )
      })}
    </>
  )
}

export default LanguagePickerList
