import { getLocaleDomain } from '@/src/utilities/languagesConfig'
import { Locale } from '@/src/constants/locale.constants'
import { AllLocalizationsType, StrapiEntryWithLocalizations } from './LanguagePickerList'
import { Router, schema } from 'next-roots'
import { routeNames as routesAboutWellbeing } from '../../../roots/(publicContent)/about-mental-wellbeing/i18n'
import { routeNames as routesAboutTherapy } from '../../../roots/(publicContent)/about-therapy/i18n'
import { routeNames as routesTopics } from '../../../roots/(publicContent)/topics/i18n'
import { routeNames as routesTests } from '../../../roots/(publicContent)/tests/i18n'
import { routeNames as routesCategory } from '../../../roots/(publicContent)/category-list/i18n'
import { routeNames as routesReview } from '../../../roots/(publicContent)/reviews/i18n'

export type LanguageVariant = {
  title: string
  slug: string
  createdAt: string
  updatedAt: string
  publishedAt: string
  locale: Locale
}

const pathToRootsTranslation = {
  article: routesAboutWellbeing,
  'about-therapy': routesAboutTherapy,
  topics: routesTopics,
  tests: routesTests,
  'category-list': routesCategory,
  reviews: routesReview,
}

const router = new Router(schema)

export const getLocalisedUrl = (
  locale: Locale,
  slug: string,
  pathname: string,
  allLocalizations: AllLocalizationsType,
) => {
  const baseLocaleUrl = `https://hedepy.${getLocaleDomain(locale)}`

  try {
    const pathSegmentCount = pathname.split('/').filter((item) => item).length

    if (pathSegmentCount === 0) {
      // https://hedepy.cz
      return baseLocaleUrl
    } else if ([1, 2].includes(pathSegmentCount)) {
      // https://hedepy.cz/about-therapy
      // https://hedepy.cz/about-therapy/picking-the-right-therapist

      // page defined in strapi, just the slug
      const isPage = slug && pathSegmentCount === 1
      if (isPage) {
        const pathname = `/${getSlugFromLocalizations(slug, allLocalizations.page, locale)}`
        return baseLocaleUrl + pathname
      }

      const untranslatedPathname = router.getRouteFromHref(pathname)
      // this is the first level of the path in the next.js router [in english], ie. "about-therapy"
      const untranslatedFirstLevelPath = untranslatedPathname.name.split('/').filter((item) => item)[0]

      const rootsTranslationFile = pathToRootsTranslation[untranslatedFirstLevelPath]
      const translatedFirstLevelPath = rootsTranslationFile?.find((item) => item.locale === locale)?.path

      // even though this could be a valid case (ie. english slug is the same as czech slug)
      // we rather return the base url than risk exposing a page that should not be available in the requested language
      if (!rootsTranslationFile || !translatedFirstLevelPath) return baseLocaleUrl

      if (pathSegmentCount === 1) {
        return `${baseLocaleUrl}/${translatedFirstLevelPath}`
      } else {
        const localizations = untranslatedFirstLevelPath === 'tests' ? allLocalizations.test : allLocalizations.article
        const translatedSlug = getSlugFromLocalizations(slug, localizations, locale)

        // if there is no translation for the slug, we return the base url
        // this means the article or test is not available in the requested language
        return translatedSlug ? `${baseLocaleUrl}/${translatedFirstLevelPath}/${translatedSlug}` : baseLocaleUrl
      }
    } else {
      // we currently don't support pages that are nested more than 2 levels deep
      // ie. /about-therapy/depression/some-arbitrary-slug
      // this can always be added later, so we prevent errors here
      return baseLocaleUrl
    }
  } catch (e) {
    // in case we missed something, returning the base url is better than crashing the app
    console.log('LanguagePickerList getLocalisedUrl error', e)
    return baseLocaleUrl
  }
}

const getSlugFromLocalizations = (originalSlug: string, entries: StrapiEntryWithLocalizations[], locale: Locale) => {
  const decodedSlug = decodeURIComponent(originalSlug)
  const page = entries?.find((item) => decodedSlug === item.attributes?.slug)
  const variant = page?.attributes.localizations.data.find((item) => item.attributes.locale === locale)

  return variant?.attributes.slug || ''
}
