import { DocumentNode } from 'graphql'
import client from '../apollo/apollo-client'
import { LOCALE } from '../constants/locale.constants'
import { rewriteTherapistCredentials } from './selectorHelpers'
import { TherapistType } from '../types/TherapistStateType'
import qs from 'qs'

export const API_URL = `${process.env.NEXT_PUBLIC_API_URL}${process.env.NEXT_PUBLIC_API_VERSION}`

export const processTherapistList = (therapists: TherapistType[]) => {
  return therapists.map((therapist) => rewriteTherapistCredentials(therapist))
}

export const fetchFromApi = async (route: string) => {
  const res = await fetch(`${API_URL}/${route}`)
  return res.json()
}

export const fetchFromStrapi = async (query: DocumentNode, variables: Record<string, any> = {}) => {
  const { data } = await client.query({
    query,
    variables: {
      locale: LOCALE,
      ...variables,
    },
  })
  return data
}

export const fetchFromStrapiRest = async (
  path: string,
  params: Record<string, any> = {},
  disableCache?: boolean,
  locale?: string,
) => {
  const url = `${process.env.NEXT_PUBLIC_CMS_URL}/api/${path}?${qs.stringify({
    locale: locale || LOCALE,
    pagination: { pageSize: 100 },
    ...params,
  })}`
  const res = await fetch(url, { ...(disableCache ? { cache: 'no-store' } : {}) })
  const json = await res.json()
  return json.data
}

export const fetchBlendedCareLesson = async (slug: string) => {
  const res = await fetchFromStrapiRest('blended-care-lessons', {
    populate: { image: true, steps: true },
    filters: { slug: { $eq: slug } },
  })
  return res[0]
}
export const fetchBlendedCareCourse = async (slug: string) => {
  const res = await fetchFromStrapiRest('blended-care-courses', {
    populate: { blended_care_lessons: { populate: { image: true } }, image: true, expertPhoto: true },
    filters: { slug: { $eq: slug } },
  })
  return res[0]
}
export const fetchAllBlendedCareCoursesFromStrapi = async () =>
  fetchFromStrapiRest('blended-care-courses', {
    populate: { blended_care_lessons: { populate: 'image' }, image: true },
  })

export const fetchAllArticlesFromStrapi = async () =>
  fetchFromStrapiRest('counselling-pages', { populate: ['cover', 'seo'], sort: 'order' })
export const fetchAllTestsFromStrapi = async () => fetchFromStrapiRest('test-pages', { populate: 'seo', sort: 'order' })
export const fetchAllVideoLessonsFromStrapi = async () =>
  fetchFromStrapiRest('online-lesson-pages', {
    populate: { lessonInfo: { populate: { lessonAuthor: { populate: 'thumbnail' } } } },
  })
export const fetchAllPagesFromStrapi = async () => fetchFromStrapiRest('pages', { populate: 'seo' })
export const fetchAllCompaniesFromStrapi = async () => fetchFromStrapiRest('company-pages')
export const fetchPageFromStrapi = async (slug: string) => {
  // WARNING: This fetch call is not cached, so it will always make a network request
  // it is for development purposes only and should be removed before merging!!!
  const res = await fetchFromStrapiRest('pages', {
    filters: { slug: { $eq: decodeURIComponent(slug) } },
    populate: 'deep',
  })
  return res?.[0]
}
export const fetchAppContentFromStrapi = async (idName: string) => {
  const res = await fetchFromStrapiRest('app-contents', {
    filters: { idName: { $eq: idName } },
    populate: 'deep',
  })
  return res[0]?.attributes.content
}

export const fetchHomeArticlesFromStrapi = async () =>
  fetchFromStrapiRest('app', {
    populate: {
      articlesBeforeFirstSession: {
        populate: 'cover',
      },
    },
  })

export const fetchPromoBarFromStrapi = async () =>
  fetchFromStrapiRest('app', {
    populate: {
      promotionsBar: {
        populate: 'cover',
      },
    },
  })

export const fetchUseTherapists = async () => {
  const therapists = await fetchFromApi('therapist/list-open')
  return processTherapistList(therapists)
}

export const fetchUseVisiblePremiumContent = async () => {
  const visibleContent = await fetchFromApi('premium-content/teasers')
  return visibleContent
}

export const fetchUseServices = async () => fetchFromApi('service')

export const fetchUseSpecializations = async () => {
  const res = await fetchFromApi('therapist/specializations')
  return res
}

export const fetchReviews = async (page: number) => {
  const res = await fetchFromApi(`review/list?page=${page}`)
  return res
}

export const fetchMenu = async (slugPrefix: string) => {
  const resMenus = await fetchFromStrapiRest('menus')
  const menuId = resMenus.find((m) => m.attributes.slug === `${slugPrefix}-${LOCALE}`)?.id
  if (!menuId) return []
  const res = await fetchFromStrapiRest(`menus/${menuId}`, {
    populate: { items: { populate: { page_relation_one: true } } },
    nested: true,
  })
  return res?.attributes.items.data || []
}

export const fetchMainMenu = async () => fetchMenu('main-menu')
export const fetchOtherMenu = async () => fetchMenu('other-menu')

export type MenuType = {
  id: number
  attributes: {
    title: string
    url?: string
    special_function: 'none' | 'therapists' | 'other'
    target?: string
    page_relation_one: { data?: { attributes: { slug: string } } }
    children?: { data: MenuType[] }
  }
}

export const fetchLocalizationVariants = async (path: string) =>
  fetchFromStrapiRest(path, { populate: 'localizations' })

type ImageDimensions = { w: number; h: number }

export const fetchAllImageDimensions = async () => {
  const res = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/upload/files`)
  const json = await res.json()

  const dimensionsByUrl: { [url: string]: ImageDimensions } = {}

  json.forEach((imgData) => {
    dimensionsByUrl[imgData.url] = { w: imgData.width, h: imgData.height }
  })

  return dimensionsByUrl
}

export const fetchSingleImageDimensions = async (src: string): Promise<ImageDimensions | null> => {
  const res = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/upload/files?_url=${src}`)
  const json = await res.json()
  const file = json[0]

  return file ? { w: file.width, h: file.height } : null
}
